//Libraries

const Production = 
    {
        api: {
            base_url:  "https://energiecoachzoeker-cms.nl/api/v1/",
            code: "pDdvuQ4FKQ25I1zbte27pTMV"
        }
    }


export default Production;