import React from 'react';

import '../css/energiecoaches.css';

const EnergiecoachCard = ({ coach, text }) => {
    return (
        <div className='card'>
            <div>
                <h3>{coach.naam}</h3>
            </div>
            <div>
                { coach.website ?
                    <p className='website'>
                        <i className="arrow right green"></i>&nbsp;
                        <a href={coach.website} target='_blank' rel="noreferrer">{coach.website.replace('https://', '').replace('http://','')}</a>
                    </p>
                : null }
                { coach.email ?
                    <p className='email'>
                        <i className="arrow right green"></i>&nbsp;
                        <a href={"mailto:" + coach.email}>{coach.email}</a>
                    </p>
                : null }
                { coach.telefoon ?
                    <p className='telefoon'>
                        <i className="arrow right green"></i>&nbsp;
                        <a href={"tel:" + coach.telefoon}>{coach.telefoon}</a>
                    </p>
                : null }
            </div>
            <div>
                <p className='biedt'>{text.offers}</p>
                <ul>
                    {coach.diensten.map((dienst, index) => (
                        <li className='dienst' key={index}>{dienst.naam}</li>
                    ))}
                </ul>
                <p className='voor'>{text.for}</p>
                <ul>
                    {coach.doelgroepen.map((doelgroep, index) => (
                        <li className='doelgroep' key={index}>{doelgroep.naam}</li>
                    ))}
                </ul>
                <p className='hoe'>{text.how}</p>
                <ul>
                    {coach.contactvormen.map((contactvorm, index) => (
                        <li className='contactvorm' key={index}>{contactvorm.naam}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default EnergiecoachCard;