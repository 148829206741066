//Libraries

const Text = {

    title: "Vind jouw energiehulp in de buurt",
    remark: "Vul je postcode in en ontdek wie jou kan helpen met energie besparen. Voor adviezen, tips of eventueel hulp bij het aanbrengen van tochtstrips of brievenbusborstel.",
    question: "Mijn postcode is:",
    placeholder: "bijvoorbeeld 1234AB",
    button_caption: "Zoek",
    error_postalcode: "Vul je volledige postcode in",

    organisations_title: "Gevonden organisaties voor de postcode: ",
    organisations_remark: "",
    organisations_not_found: "Er zijn helaas geen organisaties bij ons bekend die energiecoaches aanbieden in jouw buurt",
    others_title: "Op zoek naar ervaring van anderen?",
    others_remark: "Kijk dan naar SlimmeBuren of voorbeeldwoningen bij jou in de buurt",
    others_not_found: "Geen dienstaanbieders gevonden in de buurt",
    backbutton_caption: "Terug",
    organisations_change: "Organisatie aanmelden of afmelden?",
    organisations_website: "https://www.milieucentraal.nl/professionals/communicatietoolkits/aanmelden-tool-energiecoaches/",


    energiecoach: {
        offers: "Biedt:",
        for: "Voor:",
        how: "Hoe:"
    },
    dienstaanbieder: {
        prefix: "",
        aantal: "Aantal",
        naar_website: 'Naar website',
    }

}


export default Text;