//Libraries
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

//Components

//Styles
import '../css/home.css';

const Home = () => {
	//States
	const [postcode, setPostcode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

	//Overige
	const navigate = useNavigate();

    const text = global.text;

    const handleKeyDown = e => {
        //Als je enter indrukt in de input, dan ook redirecten
        if (e.keyCode === 13) {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        if(postcode.length < 6){
            setErrorMessage( text.error_postalcode );
        }
        else{
            navigate(`/energiecoaches/${postcode}`);
            //setErrorMessage('');
        }
    }
    

	return (
		<div className='container-home'>
			<h1>{ text.title }</h1>
			<p className='toelichting-tekst'>{ text.remark }</p>
			
            <div className='input-home input-testen'>
                <p className='label-mijn-postcode'>{ text.question } </p>
                <div className='input-with-button'>
                    <input
                        className='input-default'
                        placeholder= { text.placeholder }
                        placeholdertextcolor="#202020"
                        value={postcode}
                        onChange={(e) => setPostcode(e.target.value.trim())}
                        onKeyDown={handleKeyDown}
                        maxLength={6}
                    />
                    <button className='button-default' onClick={() => handleSubmit()}>{ text.button_caption } <i className="arrow right"></i></button>
                </div>
                {
                    errorMessage ? (
                        <p className="error">{errorMessage}</p>
                    ) : (
                        null
                    )
                }
            </div>
		</div>
	)
}

export default Home;