//Libraries
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Settings
import Development from './settings/Development';
import Staging from './settings/Staging';
import Production from './settings/Production';
import Text from './settings/Text'

//Styles
import './css/style.css';

//Images

//Pages
import Home from './pages/Home';
import Energiecoaches from './pages/Energiecoaches';

//console.log('app');

switch ( window.location.hostname ) {
  case "localhost":
    console.log("Development");
    global.settings = Development;
    break;
  case "www.energiecoachzoeker.info":
  case "energiecoachzoeker.info":
    console.log("Staging 1.1");
    global.settings = Staging;
    break;
  case "www.energiecoachzoeker.nl":
  case "energiecoachzoeker.nl":
    console.log("Production 1.2");
    global.settings = Production;
    break;
  default:
    console.log("Default omgeving");
    global.settings = Staging;
}
global.text = Text;

const App = () => {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/energiecoaches/:postcode" element={<Energiecoaches />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
