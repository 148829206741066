import React from 'react';
import BackButton from '../components/BackButton';

const ErrorMessage = ( { error } ) => {

    return (
        <div className='screen-center'>
            <div
                style={{
                    display: 'block',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '20px'
                }}
            >
                <h1 className='h1-error'>{ error.title }</h1>
                <p>{ error.message } ({error.code})</p>  
                <BackButton caption="opnieuw" />
            </div>

        </div>
    )
}

export default ErrorMessage;