//Libraries

const Development = 
    {
        api: {
            tmp_base_url: "https://energiecoachzoeker-cms.info/api/v1/",
            base_url:  "http://energiecoaches.test/api/v1/", 
            code: "pDdvuQ4FKQ25I1zbte27pTMV"
        }
    }


export default Development;