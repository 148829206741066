//Libraries
import React, { useEffect, useState  } from 'react';
import { useParams } from "react-router-dom";

//API
//import api from '../settings/AxiosSetup';

//Components
import EnergiecoachCard from '../components/EnergiecoachCard';
import DienstaanbiederCard from '../components/DienstaanbiederCard';
import BackButton from '../components/BackButton';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';

//Styles
import '../css/energiecoaches.css';

const Energiecoaches = () => {
	//States
	const [coaches, setCoaches] = useState([]);
	const [dienstaanbieders, setDienstaanbieders] = useState([]);
	const [loading, setLoading] = useState( true );
	const [error, setError] = useState( false );

	//Params
	let { postcode } = useParams();

	//Overige
    const text = global.text;
    //alert("energiecoaches ophalen");

    const getCoaches = async (postcode) => {
		console.log("haal energiecoaches op bij " + postcode);

        /*try {
            const response = await api({
				methode: 'get',
				url: `energiecoach?postcode=${postcode}&code=${code}`,
				baseURL: global.settings.api.base_url
			});
            // Success
            if (response.data.success) {
                //console.log(response.data);
				const data = response.data.data;
				setCoaches(data.energiecoaches);
				setDienstaanbieders(data.dienstenaanbieders);
				setLoading( false );
            }
            else {
            	console.log(response.data);
            	setError( {code: 0, title: "Foute response API", message: response.data.error});
            	setLoading( false );
            }
        } catch (err) {
			console.log(err);
            // Error, nog opvangen
            if (err.response) {
				if (err.response.status === 401) {}
				if (err.response.status === 403) {}
				if (err.response.status === 429) {}
				if (err.response.status === 500) {}

				setError( {code: err.response.status, title: "Fout API", message: "De gegevens konden niet van de server worden opgehaald, probeer het opnieuw."});
          		setLoading( false );
            }
        }
        */
        try {
	        const response = await fetch(global.settings.api.base_url + `energiecoach?postcode=${postcode}&code=${global.settings.api.code}`);
	        const json = await response.json();
	        console.log(json);

            if (json.success) {
                //console.log(json.data);
				const data = json.data;
				setCoaches(data.energiecoaches);
				setDienstaanbieders(data.dienstenaanbieders);
            }
            else {
            	console.log(json);
            	setError( {code: 0, title: "Foute response API", message: json.data.error});
            }
	        setLoading(false);
	    }
	 	catch (error) {
			setError( {code: 0, title: "Fout API", message: "De gegevens konden niet van de server worden opgehaald, probeer het opnieuw."});
	      	console.log(error);
	    } finally {
	      	setLoading(false);
	    }
    }


	useEffect(() => {
		getCoaches(postcode);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if ( loading ) {
		//console.log('loader');
		return <Loader color="#054C38" size={100} radius={5} />
	}
	else if ( error ) {
		//console.log('error');
		return <ErrorMessage error={error} />
	}
	else {
		//console.log('render coaches');
		return (
			<div className='container-ec'>
				<BackButton caption={ text.backbutton_caption } />
				<h1 className='h1-top'>{ text.organisations_title }{postcode}</h1>
				{
					coaches?.length > 0 ?
					(
						<div className="energiecoaches">
							{coaches.map((coach, index) => (
								<EnergiecoachCard key={index} coach={coach} text={text.energiecoach} />
							))}
						</div>
					) : (
						<p className='not-found'>{ text.organisations_not_found }</p>
					)
				}
    			<a href={ text.organisations_website } target="_blank" rel="noreferrer">{ text.organisations_change }</a>
    			<br/><br/>

				<h1>{ text.others_title }</h1>
				<p className='subtitle'>{ text.others_remark }</p>
				<div className="dienstaanbieders">
					{/* TIJDELIJK */}
					<div className="card">
						<div>Wil je maatregelen uitvoeren om je woning te verduurzamen?</div>
						<div>Bekijk inspirerende voorbeeldwoningen in jouw buurt op <a href="https://duurzamehuizenroute.nl" target="_blank" rel="noreferrer">duurzamehuizenroute.nl</a>.</div>
					</div>
					<div className="card">
						<div>Vind SlimmeBuren die ervaring hebben met energiebesparende maatregelen op <a href="https://www.hier.nu/slimmebuur" target="_blank" rel="noreferrer">SlimmeBuur.nl</a>.</div>
					</div>
{/*					{dienstaanbieders.map((dienstaanbieder, index) => (
						<DienstaanbiederCard key={index} dienstaanbieder={dienstaanbieder} text={text.dienstaanbieder} />
					))}*/}
				</div>
				
			</div>
		)
	}
}

export default Energiecoaches;