import React from 'react';
import { BallTriangle } from  'react-loader-spinner'

const Loader = ( { color, size, radius } ) => {

    return (
        <div className='screen-center'>
            <BallTriangle
                height={ size }
                width={ size }
                radius={ radius }
                color={ color }
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />  
        </div>
    )
}

export default Loader;