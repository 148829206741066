//Libraries
import React from 'react';
import { useNavigate } from "react-router-dom";

import '../css/backbutton.css';

const BackButton = ( { caption } ) => {
	const navigate = useNavigate();

    return (
        <div className='terug-div'>
            <p className='terug-knop' onClick={() => navigate('/')}><i className="arrow left"></i>{ caption }</p>
        </div>
    )
}

export default BackButton;