//Libraries

const Staging = 
    {
        api: {
            base_url:  "https://energiecoachzoeker-cms.info/api/v1/",
            code: "pDdvuQ4FKQ25I1zbte27pTMV"
        }
    }


export default Staging;